import React from 'react';

import CustomDate from '../../CustomInputs/CustomDate';
// import CustomDropdown from '../../CustomInputs/CustomDropdown';
// import CustomGroupSelection from '../../CustomInputs/CustomGroupSelection';
import CustomRadioSelection from '../../CustomInputs/CustomRadioSelection';
import CustomTextArea from '../../CustomInputs/CustomTextArea';
import CustomTextField from '../../CustomInputs/CustomTextField';
// import LargeToggle from '../../CustomInputs/LargeToggle';
// import Categories from "../../CustomInputs/Categories";
// import RichText from '../../../../../utils/RichText';
import './questReq.css'

const questReq = ({ form, handleFormChange, errors, platformOptions }) => (
    <>

        <CustomTextField
            header="What type of Influencer would you like to work with? (Describe your ideal candidate) "
            name="requireInfluencer"
            value={form.requireInfluencer}
            onChange={handleFormChange}
            error={errors.includes('requireInfluencer')}
        />
        <CustomTextField
            header="What games would your ideal candidate play or have played?"
            name="requirePlay"
            value={form.requirePlay}
            onChange={handleFormChange}
            error={errors.includes('requirePlay')}
        />

        <CustomTextArea
            header="Talking points/creative instructions Creators should be aware of:"
            name="requireCreators"
            value={form.requireCreators}
            onChange={handleFormChange}
            error={errors.includes('requireCreators')}
        />
        <h3 className="inputText_title">Video Title</h3>
        <div className="inputText">
            <CustomTextField
                header="Title:"
                name="videoTitle"
                value={form.videoTitle}
                onChange={handleFormChange}
                error={errors.includes('videoTitle')}
            />
            <CustomTextField
                header="Hashtag:"
                name="videoHashtag"
                value={form.videoHashtag}
                onChange={handleFormChange}
                error={errors.includes('videoHashtag')}
            />
        </div>
        <h3 className="inputText_title">Chatbot (if applicable)</h3>
        <div className="inputText">
            <CustomTextField
                header="Copy (optional):"
                name="chatbotCopy"
                value={form.chatbotCopy}
                onChange={handleFormChange}
                error={errors.includes('chatbotCopy')}
            />
            <CustomTextField
                header="Command:"
                name="chatbotCommand"
                value={form.chatbotCommand}
                onChange={handleFormChange}
                error={errors.includes('chatbotCommand')}
            />
        </div>
            <CustomRadioSelection
                header="Demographics (select all that apply)"
                name="demographics"
                value={form.demographics}
                onChange={handleFormChange}
                error={errors.includes('demographics')}
                options={[
                    /*{ optionName: 'Video Game', optionValue: 'game' },
					{ optionName: 'Product/event/other', optionValue: 'non-game' },*/
                    {optionName: 'Europe', optionValue: 'europe'},
                    {optionName: 'North', optionValue: 'north'},
                    {optionName: 'America', optionValue: 'america'},
                    {optionName: 'Latin', optionValue: 'latin'},
                    {optionName: 'Asia', optionValue: 'asia'},
                    {optionName: 'Australia', optionValue: 'australia'},
                ]}
            />
            <CustomTextArea
                header="Please list any countries or states you would like to exclude:"
                name="countries"
                value={form.countries}
                onChange={handleFormChange}
                error={errors.includes('countries')}
            />
            <CustomDate
                header="CAMPAIGN START DATE"
                name="campaingStartDate"
                value={form.campaingStartDate}
                onChange={handleFormChange}
                error={errors.includes('campaingStartDate')}
            />
            <CustomDate
                header="CAMPAIGN START DATE"
                name="campaingEndDate"
                value={form.campaingEndDate}
                onChange={handleFormChange}
                error={errors.includes('campaingEndDate')}
            />
        </>
        );

        export default questReq;
