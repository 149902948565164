import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Tooltip } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import questBackgrounds from '../../images/backgrounds/questPage/index';

// Components (internal)
import Back from '../shared/Back';
import Carousel from '../../global/components/carousel/Carousel';
import Countries from './Countries';
import FAQs from './FAQs';
import GameGallery from './GameGallery';
import GameSection from './GameSection';
import GamesPlayed from './GamesPlayed';
import Languages from './Languages';
import QuestModal from './QuestModal';
import QuestSelector from './QuestSelector';
import RequiredStreamTime from './RequiredStreamTime';
import StatusBar from './StatusBar';

// Components (external)
import ItemCard from './ItemCard';
import VideoWidget from './VideoWidget';

import { longFade } from '../../utils/transitionIndex';
import Loading from '../loading/Loading';

// Styles
import './Quest.scss';

import {
  getDefaultIcon,
  isMongoId,
  isStreamingQuest,
  onlyUniqueByKey,
  wordTruncate,
} from '../../utils/functions';

const TABLET_SCREEN_WIDTH = 1024;

// Need addressing for Status
const status = '';

const returnBGStyle = (activeBackground, defaultBg) => {
  const defaultBack = `linear-gradient(to top, transparent 60%, rgba(24, 25, 29, 1) 95%), linear-gradient(to bottom, transparent 60%, rgba(24, 25, 29, 1) 95%), linear-gradient(to left, transparent 60%, rgba(24, 25, 29, .9) 98%), linear-gradient(to right, transparent 60%, rgba(24, 25, 29, .9) 98%), url(${activeBackground})`;
  const noGallery = `linear-gradient(to top, transparent 60%, rgba(24, 25, 29, .5) 95%), linear-gradient(to bottom, transparent 60%, rgba(24, 25, 29, .5) 95%), linear-gradient(to left, transparent 60%, rgba(24, 25, 29, .4) 98%), linear-gradient(to right, transparent 60%, rgba(24, 25, 29, .4) 98%),url(${defaultBg}`;
  const switchSpread = activeBackground ? { backgroundImage: defaultBack } : { backgroundImage: noGallery };
  return {
    transition: 'all .5 ease',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ...switchSpread,
  };
};

const fillUpGallery = (gallArr = []) => {
  const gradients = questBackgrounds;
  const preppedGrads = gradients.map(item => ({ url: '', pureGrad: item }));
  const gallLength = gallArr.length || 0;
  let temp = [];
  if (!gallArr || !gallLength) return preppedGrads;
  if (gallLength < 4) {
    temp = [...gallArr, ...preppedGrads];
  } else {
    temp = [...gallArr];
  }
  temp.length = 6;
  return temp;
};



const Quest = (props) => {
  const {
    location,
    router,
    auth: {
      token,
      user = {
        connectedAccounts: { twitch: {} },
        statistics: {},
      },
    },
    config: {
      quest: { data: quest = {} },
    },
    home: {
      quests: { all: { data: { legacy: quests = [] } } },
    },
    user: {
      myUserQuests: { data: userQuests = [], errors = {}, isLoading: myUserQuestsLoading },
    },
    getAllGuestQuests,
    getMyQuestsPageData,
    getOptimizedAllQuests,
    getQuestByIdentifier,
    getQuestBySlugGuest,
    joinQuest,
    getInitialInfo,
  } = props;

  const {
    countries = [],
    faqs = [],
    game = {},
    id: questId = '',
    languages = ['en'],
    prevGamesPlayed = [],
    requiredStreamTimeMinutes = 0,
    requirements,
    description: questDescription = '',
    stepsPage1: questions = [],
    title = 'Quest Title',
    viewerTiers = [],
  } = quest;

  const {
    categories: genres = [],
    cover: coverImage = '',
    gamePageURL = '',
    headline: tagline = '',
    headlineVideo = '',
    logo = '',
    description = '',
    developer = '',
    gallery = [],
    id: gameId = '',
    name = '',
    publisher = '',
    sponsorType = 'game',
  } = game;
  // const videoBgRef = React.createRef();
  // The following lines is required because you can't default values for NULL. Yay JavaScript.
  const reqs = requirements || { beforeStream: [], duringStream: [], afterStream: [] };
  const { beforeStream, duringStream, afterStream } = reqs;
  const minACCV = viewerTiers && viewerTiers.length ? viewerTiers.reduce((prev, curr) => (prev < curr.minimumAverageViewers ? prev : curr.minimumAverageViewers), Number.MAX_SAFE_INTEGER) : false;
  const deliverables = [...(beforeStream || []), ...(duringStream || []), ...(afterStream || [])];
  const filteredGallery = gallery.filter(g => g.type === 'image');
  const preppedCarouselQuests = quests ?
    quests.filter(item => item.slug !== quest.slug).map((q) => {
      const {
        game: { cover = '' },
        slug: questSlug = '',
        title: questTitle = '',
      } = q;

      return { slug: questSlug, questTitle, cover };
    })
    : [];

  const [questSelectorSticky, setQuestSelectorSticky] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [navStickyBottomPos, setNavStickyBottomPos] = useState(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [userQuest, setUserQuest] = useState({});
  const [bgIndex, setBgIndex] = useState(0);

  const QuestContainer = useRef();
  const StopTheDrop = useRef();

  // Listener function for window scroll
  const handleScroll = () => {
    const width = window.innerWidth > TABLET_SCREEN_WIDTH;
    if (QuestContainer.current) { // Handle side nav sticky on scroll
      // Stick when we hit top of scroll container
      if (window.scrollY >= 100 && width) {
        setQuestSelectorSticky(true);
        // If we pass top of container on the way back up, unstick
      } else {
        setQuestSelectorSticky(false);
      }
    }

    // Confirm if we can see footer or not
    const footerVisible = window.innerHeight - StopTheDrop.current.getBoundingClientRect().top >= 150;
    // Make quest selector fixed when footer is in view
    if (footerVisible && !navStickyBottomPos && width) {
      setNavStickyBottomPos(window.scrollY);
    } else if (!footerVisible) {
      setNavStickyBottomPos(false);
    }
  };

  const filledGallery = fillUpGallery(filteredGallery) || [];
  const backgroundImages = filledGallery.map(({ url, pureGrad = '' }, i) => (
    <CSSTransition key={i} {...longFade(3000, 3000, true, true, true)}>
      <div className="bg-manager" style={returnBGStyle(url, pureGrad)} />
    </CSSTransition>
  ));

  useEffect(() => {
    const path = window.location.pathname;
    const pathArr = path.split('/');
    const identifier = pathArr[pathArr.length - 1];
    const isQuestId = isMongoId(identifier);
    if (token) {
      getQuestByIdentifier(identifier, token, isQuestId ? 'id' : 'slug');
      getMyQuestsPageData(token);
      getOptimizedAllQuests('all', 1, token);
    } else {
      getQuestBySlugGuest(identifier);
      getAllGuestQuests();
    }
  }, []);

  // Set up Background Images Interval
  useEffect(() => {
    let i = 0;
    const bgInterval = setInterval(() => {
      i += 1;
      if (i >= backgroundImages.length - 1) i = 0;
      setBgIndex(i);
    }, 18000);
    return () => clearInterval(bgInterval);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    const foundUQ = userQuests.find(q => q.quest === quest.id);
    if (foundUQ) setUserQuest(foundUQ);
    return () => setUserQuest({});
  }, [userQuests]);

  const bgItemCards = onlyUniqueByKey(preppedCarouselQuests, 'cover').map((item, i) => (
    <ItemCard
      item={{
        image: item.cover,
        title: item.title || item.questTitle,
        slug: item.slug,
      }}
      showLearnMore
    />
  ));

  const oneMoreQuestOnly = bgItemCards && bgItemCards.length === 1;
  const hasRequirements = !!minACCV || !!countries.length || !!languages.length || !!prevGamesPlayed.length;
  const sameGameQuests = quests.filter(q => q.game && q.game.id === gameId);
  const filteredDeliverables = deliverables.filter(dev => dev.title && dev.showOnApp);
  const finalDeliverables = filteredDeliverables.length ? filteredDeliverables : deliverables.filter(dev => dev.title);
  const enableVideoWidget = headlineVideo || coverImage;
  const streamingQuest = isStreamingQuest(quest);

  return (
    // <div className="bg-manager">
    <>
      <Loading isLoading={!quest} />
      {!!backgroundImages.length &&
        <div style={{ opacity: 0.23 }}>
          <TransitionGroup>
            {/* <CSSTransition {...longFade(0, 3000, false, false, true)}>
              <video ref={videoBgRef} autoPlay muted playbackrate="0.2" loop id="maintenance-mode-myVideo" style={{ opacity: '.15' }}>
                <source src="https://http-error-pages.s3.us-west-1.amazonaws.com/spaceBack.5f74cb3c.mp4" type="video/mp4" />
              </video>
            </CSSTransition> */}
            {backgroundImages[bgIndex]}
          </TransitionGroup>
        </div>
      }
      <div className="BigContainer">
        <div className="control-container">
          <div
            className={classNames('NoizModal', {
              open: modalOpen,
            })}
            onClick={() => setModalOpen(false)}
          />
          <div
            className={classNames('NoizModalOverlay', {
              open: modalOpen,
            })}
          />
          <QuestModal
            activeQuestionIndex={activeQuestionIndex}
            closeModal={() => setModalOpen(false)}
            joinQuest={joinQuest}
            nextQuestion={() => setActiveQuestionIndex(activeQuestionIndex + 1)}
            open={modalOpen}
            prevQuestion={() => setActiveQuestionIndex(activeQuestionIndex - 1)}
            questId={questId}
            setActiveQuestionIndex={setActiveQuestionIndex}
            questions={questions}
            token={token}
            getInitialInfo={getInitialInfo}
          />
          <StatusBar status={status} />
          <div className="QuestContainer" id="quest-container" ref={QuestContainer}>
            {token && <Back />}
            {enableVideoWidget && <VideoWidget img={coverImage} src={headlineVideo} />}
            <div className="QuestSelectorContainer" id="quest-selector-container">
              <QuestSelector
                currentQuest={quest}
                handleApply={() => setModalOpen(true)}
                navStickyBottomPos={navStickyBottomPos}
                questDates={sameGameQuests}
                status={status}
                sticky={questSelectorSticky && !navStickyBottomPos}
                title={title}
                user={user}
                userQuest={userQuest}
                token={token}
                stopWidth={TABLET_SCREEN_WIDTH}
                errors={errors}
                userQuestIsLoading={myUserQuestsLoading}
                router={router}
                location={location}
              />
            </div>
            {!!deliverables.length && (
              <div className="DeliverablesContainer make-visible" style={{ margin: !headlineVideo ? '1% 0% 0% 0%' : '64px 0px 0px 0px' }}>
                <h1> Deliverables</h1>
                <div className="deliverablesList make-visible">
                  {finalDeliverables.map(dev => (
                    <div
                      className="deliverable make-visible"
                      key={uuidv4()}
                    >
                      <div className="iconBg">
                        <img
                          alt="icon"
                          className="icon"
                          src={getDefaultIcon(dev.type, dev.subtype || '', quest || '', game || '')}
                        />
                      </div>
                      <Tooltip title={dev.appPageTitle || dev.title} placement="top">
                        <span>
                          {wordTruncate(dev.appPageTitle || dev.title, 4)}
                        </span>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <GameSection
              gameDescription={description}
              questDescription={questDescription}
              developer={developer}
              sponsorType={sponsorType}
              gamePageURL={gamePageURL}
              genres={genres}
              id={gameId}
              logo={logo}
              gameTitle={name}
              publisher={publisher}
              tagline={tagline}
              tabWidth={800}
            />
            {!!faqs.length && <FAQs faqs={faqs} />}
            {filteredGallery.length > 0 && <GameGallery gallery={filteredGallery} />}
            {hasRequirements && (
              <div className="EligibilityContainer make-visible">
                <h1>Eligibility Requirements</h1>
                <div className="eligibilityList">
                  {(!!minACCV && minACCV > 0) && (
                    <div className="eligibilityItem make-visible">
                      <span className="minACCV">{minACCV}</span>
                      <Tooltip title="Average Concurrent Viewers" placement="bottom">
                        <span className="label">Minimum ACCV</span>
                      </Tooltip>
                    </div>
                  )}
                  {!!countries.length && (
                    <Countries className="make-visible" countries={countries} />
                  )}
                  {!!languages.length && (
                    <Languages className="make-visible" languages={languages} />
                  )}
                  {!!prevGamesPlayed.length && (
                    <GamesPlayed className="make-visible" games={prevGamesPlayed} />
                  )}
                  {streamingQuest && requiredStreamTimeMinutes > 0 &&
                    <RequiredStreamTime className="make-visible" isStreamingQuest={streamingQuest} requiredStreamTimeMinutes={requiredStreamTimeMinutes} />
                  }
                </div>
              </div>
            )}
          </div>
          <div className="stop-the-drop" ref={StopTheDrop} />
          {(bgItemCards && !!bgItemCards.length) && (
            <div className="MoreQuestsContainer">
              <h1 className="quest-container-title">
                More Quests
              </h1>
              <div style={{ width: oneMoreQuestOnly ? '50%' : '100%' }}>
                <Carousel
                  centerMode={!oneMoreQuestOnly}
                  centerSlidePercentage={50}
                  items={bgItemCards}
                  screenBreak={TABLET_SCREEN_WIDTH}
                  width="100%"
                  showArrows={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Quest;
