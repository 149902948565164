import React from 'react';
import './Onboard.scss';
import './hatchway.css';
import {Link} from "react-router";
import IconParticles from "../icon-particles/IconParticles";

const Hatchway = () => {
	return (
		<section className="Onboard">
			<section className="Hero">
				<IconParticles/>
				<section className="Hero__content">
					<h2 className="Hero__title">Brand Portal</h2>
				</section>
			</section>
			<section className="Onboard__container">
				<div className="hatchway">
					<div className="box">
						<h2>start your <em>quest</em></h2>
						<p>Let's make some <em>NOIZ</em></p>
						{/*<a className="btn" href="/portal?component=ProductEdit&tab=home">create quest</a>*/}
						<Link className="btn" to="/portal?component=ProductEdit&tab=home">
							create quest
						</Link>
					</div>
					<div className="box">
						<h2>your quest</h2>
						{/*<a className="btn" href="https://noiz.gg/portal?component=default&tab=home">portal</a>*/}
						<Link className="btn" to="/portal?component=default&tab=home">
							portal
						</Link>
					</div>
				</div>

			</section>
		</section>

	);
}


export default Hatchway;