import React from 'react';
// import { Link } from 'react-router';

import IconParticles from '../icon-particles/IconParticles';
import ContactForm from '../brand-service/slides/ContactForm';
import BrandLoginForm from '../brand-service/slides/BrandLoginForm';

import './Onboard.scss';

const Onboard = () => {
  return (
    <section className="Onboard">
      <section className="Onboard__container">

        <section className="Hero">
          <IconParticles />
          <section className="Hero__content">
            <h2 className="Hero__title">Brand Portal</h2>
          </section>
        </section>

        <section className="MainOnboard">
          <ContactForm />
          <BrandLoginForm />
        </section>
        <section className="studies">
          <h2>RECENT CASE STUDIES</h2>
          <ul>
            <li>
              <a href="https://drive.google.com/file/d/1i_OxjqrWrDKYxy5vWFCeQUQUeKZydbx-/view?usp=drive_link">
                <img src="/study-bellwright.jpg" alt=""/>
                <h4>Bellwright</h4>
              </a>
            </li>
            <li>
              <a href="https://drive.google.com/file/d/1GdAxLHKsbAwcDKZF31W8mmog4L6gvXGO/view?usp=sharing">
                <img src="/study-ark.jpg" alt=""/>
                <h4>ARK: Survival Ascended</h4>
              </a>
            </li>
          </ul>
        </section>
      </section>
    </section>);
};

export default Onboard;
