import React from 'react';

// import Categories from '../../CustomInputs/Categories';
import CustomTextField from '../../CustomInputs/CustomTextField';
import SocialNetworks from '../../CustomInputs/SocialNetworks';
import CustomTextArea from "../../CustomInputs/CustomTextArea";

const Social = ({ form, handleFormChange, errors }) => (
  <>
      <h3 className="inputText_title">SOCIAL MEDIA COPY</h3>
      <div className="inputText inputText1">
          <CustomTextField
              header="Hashtag:"
              name="socialHashtag"
              value={form.socialHashtag}
              onChange={handleFormChange}
              error={errors.includes('socialHashtag')}
          />
          <CustomTextField
              header="Other hashtag (optional)"
              name="socialOther"
              value={form.socialOther}
              onChange={handleFormChange}
              error={errors.includes('socialOther')}
          />
          <CustomTextField
              header="Mention:"
              name="socialMention"
              value={form.socialMention}
              onChange={handleFormChange}
              error={errors.includes('socialMention')}
          />
          <CustomTextArea
              header="Suggest copy or creative direction:"
              name="socialSuggest"
              value={form.socialSuggest}
              onChange={handleFormChange}
              error={errors.includes('socialSuggest')}
          />
      </div>
    <SocialNetworks
      value={form.socialNetworks}
      form={form}
      handleFormChange={handleFormChange}
      enableNameInCards={false}
    />
  </>
);

export default Social;
