import React, { useEffect } from 'react';

import PortalNav from './PortalNav';
import PortalMainView from './PortalMainView';
import { SetViewContext, defaultBaseView, BrandUserContext } from './PortalContext';
import { isPortalWithNoParams, pushComponentState } from './NavManager';

import './Portal.scss';

const Portal = (props) => {
  const {
    admin,
    auth,
    auth: { token },
    router,
    router: { location: { pathname = '/portal', query = defaultBaseView } },
    getAllPlatforms,
    getBrandGame,
    getBrandQuest,
    getBrandStreamer,
    getBrandUserQuest,
    getBUCampaign,
    getBUCampaignList,
    postBrandPreapproval,
    postBrandGame,
    postBrandQuest,
    postBrandRequirement,
    putBrandGame,
    putBrandQuest,
    brandUserUploadImageUrl,
    brandUser,
    updateProfileInfo
  } = props;

  useEffect(() => {
    window.Intercom('boot', {
      app_id: 'ito0ceux',
      hide_default_launcher: true,
    });
    if (isPortalWithNoParams()) {
      pushComponentState(defaultBaseView, router, pathname);
    }
    getBUCampaignList(token);
  }, []);

  const nav = [
    {
      icon: 'house',
      tabName: 'home',
      component: '',
      mvp: true
    },
    /*{
      icon: 'key',
      tabName: 'keys',
      component: ''
    },*/
    {
      icon: 'scroll',
      tabName: 'quests',
      component: '',
      mvp: true

    },
    /* {
      icon: 'gamepad',
      tabName: 'discover',
      component: '',
      mvp: true
    }, */
  ];

  return (
    <SetViewContext.Provider
      value={{
        setView: s => pushComponentState(s, router, pathname),
        view: { ...query },
      }}
    >
      <BrandUserContext.Provider
        value={{
          brandUser: {
            ...brandUser,
            user: auth?.user || {},
          },
          auth,
          brandUserUploadImageUrl,
          updateProfileInfo,
          getAllPlatforms,
          getBrandGame,
          getBrandQuest,
          getBrandStreamer,
          getBrandUserQuest,
          getBUCampaign,
          getBUCampaignList,
          platforms: admin?.platforms,
          postBrandPreapproval,
          postBrandGame,
          postBrandQuest,
          postBrandRequirement,
          putBrandGame,
          putBrandQuest,
          product: admin?.game,
          query,
          token,
        }}
      >
        <div className="PortalWrapper">
          <div className="PortalClass">
            <PortalNav
              auth={auth}
              getBrandGame={getBrandGame}
              getBrandQuest={getBrandQuest}
              router={router}
              navDict={nav}
              view={query}
              setView={(s) => pushComponentState(s, router, pathname)}
            />
            <PortalMainView
              getBrandGame={getBrandGame}
              getBrandQuest={getBrandQuest}
              getBrandStreamer={getBrandStreamer}
              getBrandUserQuest={getBrandUserQuest}
              getBUCampaign={getBUCampaign}
              getBUCampaignList={getBUCampaignList}
              auth={auth}
              brandUser={brandUser}
              view={query}
              token={token}
            />
          </div>
        </div>
      </BrandUserContext.Provider>
    </SetViewContext.Provider>
  )
};

export default Portal;
