import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';


import {
	withStyles,
	makeStyles,
} from '@material-ui/core/styles';

import {
	FormControl,
	TextField,
} from '@material-ui/core';

import { updateContact } from '../../../utils/apiv2';
import { isProduction } from '../../../utils/functions';
import { simpleNotification } from '../../../utils/notifications';

import './BrandServiceSlides.scss';
import {Link} from "react-router";





const validateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (!re.test(String(email).toLowerCase())) {
		return false;
	}

	if (email.includes('@gmail.com') || email.includes('@hotmail.com') || email.includes('@yahoo.com') || email.includes('@ymail.com')) {
		return false;
	}

	return true;
};



const isMobile = window.innerWidth < 700;

const CustomInput = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			color: 'white !important',
			fontWeight: 400,
		},
	},
}))(TextField);

const useStyles = makeStyles(theme => ({
	root: {
		// display: 'flex',
		// flexDirection: 'column',
		border: 0,
		borderRadius: 0,
		'& .MuiTextField-root': {
			borderRadius: 0,
			fontSize: 16,
			color: 'white !important',
			width: isMobile ? '80vw !important' : '360px !important',
			backgroundColor: 'rgba(255, 255, 255, 0.05)',
			maxWidth: '80vw',

			marginTop: isMobile ? theme.spacing(1.5) : theme.spacing(3),
			marginBottom: isMobile ? theme.spacing(3) : theme.spacing(3),

			//   paddingTop: 0,
			//   paddingBottom: 0,
		},
		'& .MuiInputBase-input': {
			borderRadius: 0,
			border: 0,
			padding: 10,
			fontSize: 16,
		},
		'& .MuiSelect-root': {
			maxHeight: 58,
			border: 0,
			color: 'white !important',
		},
	},
}));

export default function CustomizedInputs() {
	useEffect(() => {
		ReactGA.initialize('UA-116227928-1');
	}, []);

	const classes = useStyles({
		formLabel: {
			color: '#000',
			'&.Mui-focused': {
				color: '#000',
			},
		},
	});


	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');


	const [errors, setErrors] = useState({});

	const [hasInteracted, setHasInteracted] = useState(false);

	const clearForm = () => {

		setEmail('');

		setPassword('');

	};

	const sendInteractionToGoogle = () => {
		if (!hasInteracted && isProduction()) {
			ReactGA.event({
				category: 'form interactions',
				action: 'touched_form',
				label: 'touched form',
			});
			setHasInteracted(true);
		}
	};

	const sendConversionToGoogle = () => {
		if (isProduction()) {
			ReactGA.event({
				category: 'form interactions',
				action: 'form_submitted',
				label: 'form submitted',
			});
		}
	};

	const verifyFields = () => {
		console.log('fl2')
		const errors = {};


		if ((!email || !validateEmail(email)) && !validateEmail(email)) {
			errors.email = true;
		}
		if (!password) {
			errors.password = true;
		}else{
			if (!/[*@!#%&()^~{}$]+/.test(password)) {
				errors.password = true;
			}

			if (!/[A-Z]+/.test(password)) {
				errors.password = true;
			}

			if (!/[a-z]+/.test(password)) {
				errors.password = true;
			}

			if (password.length > 30 || password.length < 8) {
				errors.password = true;
			}
		}



		setErrors(errors);

	};

	const verifyField = field => {
		console.log(field,email, password)
		if (field === 'email') {
			if (!validateEmail(email)) {
				setErrors({ ...errors, email: true });
			} else {
				const { email, ...rest } = errors;
				setErrors(rest);
			}
		}
		if (field === 'password') {
			if (password === '') {
				setErrors({...errors, password: true});
			}else{
				const { password, ...rest } = errors;
				setErrors(rest);
			}
		}

	};

	const handleSubmit = () => {
		verifyFields();

		if (email && password) {
			if (Object.keys(errors).length === 0) {
				console.log('suc')
				axios.post('api/v1/auth/sign-in', {
					browserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					identity: email,
					password: password,
					role: 'brand',
				}).then(response => {
					// 处理响应
					// console.log(response);
					if(response.status === 200){
						// console.log(response);
						localStorage.setItem('token', response.data.token);
						window.location.href = '/brand/hatchway';

					}
				}).catch(error => {
					console.error(error.response)

					if(error.response.status === 400){
						// alert('ACCOUNT_NOT_FOUND')
						simpleNotification({
							level: 'error',
							title: 'ACCOUNT NOT FOUND',
							autoDismiss: 15,
							position: 'br',
							message: "Account not found with these credentials",
						});
					}
				});

				// setSteps(1);
			}
		} else {
			console.log('fail')
			submit();
		}
	};

	const submit = async () => {
		if (email && !validateEmail(email)) {
			simpleNotification({
				level: 'error',
				title: 'Valid Email Required',
				autoDismiss: 5,
				position: 'bl',
				message: 'Please enter a valid business email',
			});
			return null;
		}

		if (email) {
			let response;
			try {
				response = await updateContact({
					email,
					password,
				});
			} catch (err) {
				simpleNotification({
					level: 'error',
					title: 'Something Went Wrong',
					autoDismiss: 10,
					position: 'bl',
					message: "We're sorry something went wrong!  Please try the chat widget on your right!",
				});
			}
			sendConversionToGoogle();
			if (response && response.status === 200) {
				simpleNotification({
					level: 'success',
					title: 'Got it!',
					position: 'bl',
					autoDismiss: 10,
					message: 'One of our team members will be in touch right away!',
				});
				clearForm();
			}
		} else {
			simpleNotification({
				level: 'error',
				title: 'Email and Password Required',
				autoDismiss: 5,
				position: 'bl',
				message: "We'd love to connect! Please add at least a name and email",
			});
		}
	};

	const handleChange = (field, event) => {
		sendInteractionToGoogle();
		switch (field) {

			case 'email':
				setEmail(event.target.value);
				break;

			case 'password':
				setPassword(event.target.value);
				break;
			default:
				break;
		}
	};

	return (
		<div className="brand-form-control brand-form-control-brandLogin">
			<p className="brand-form-control-hint">Ready to get started? <Link to="/portal/sign-up">CREATE YOUR ACCOUNT</Link></p>
			<h2>Brand Login</h2>
			<form className={classes.root} style={{ display: 'flex', flexDirection: 'column' }}>
				<>
					<FormControl className={classes.margin}>
						<div className="contact-form-label" >
							EMAIL
						</div>
						<CustomInput
							required
							error={errors.email}
							value={email}
							onBlur={() => verifyField('email')}
							onChange={e => handleChange('email', e)}
						/>
					</FormControl>
					<FormControl className={classes.margin}>
						<div className="contact-form-label" >
							Password
						</div>
						<CustomInput
							type="password"
							required
							error={errors.password}
							value={password}
							onBlur={() => verifyField('password')}
							onChange={e => handleChange('password', e)}
						/>
					</FormControl>

				</>

			</form>
			<div>
				<button disabled={Object.keys(errors).length > 0} onClick={handleSubmit} className="brand-rec-button">Continue</button>
			</div>
		</div>
	);
}
