import React from 'react';

import playIcon from '../../global/images/icons/play_button.svg';

const RequiredStreamTime = (props) => {
  const {
    isStreamingQuest = false,
    requiredStreamTimeMinutes = 0,
  } = props;

  if (!isStreamingQuest) return null;

  return (
    <div className="eligibilityItem">
      <img src={playIcon} alt="feedback" />
      <span className="label">
        Stream for {requiredStreamTimeMinutes} minutes
      </span>
    </div>
  );
};

export default RequiredStreamTime;
