import React from 'react';

import CustomDate from '../../CustomInputs/CustomDate';
// import CustomDropdown from '../../CustomInputs/CustomDropdown';
// import CustomGroupSelection from '../../CustomInputs/CustomGroupSelection';
import CustomRadioSelection from '../../CustomInputs/CustomRadioSelection';
import CustomTextArea from '../../CustomInputs/CustomTextArea';
import CustomTextField from '../../CustomInputs/CustomTextField';
// import LargeToggle from '../../CustomInputs/LargeToggle';
import Categories from "../../CustomInputs/Categories";
// import RichText from '../../../../../utils/RichText';

const General = ({ form, handleFormChange, errors, platformOptions }) => (
  <>
    {/*<LargeToggle
      header='PRODUCT STATUS'
      leftColor='#393939'
      // leftIcon='sun'
      rightColor='#4be0ca'
      // rightIcon='moon'
      name='status'
      onChange={handleFormChange}
      value={form.status}
    />*/}
    <CustomTextField
      header="PRODUCT NAME"
      name="name"
      value={form.name}
      onChange={handleFormChange}
      error={errors.includes('name')}
    />
    <CustomRadioSelection
      header="SPONSORSHIP TYPE"
      name="sponsorType"
      value={form.sponsorType}
      onChange={handleFormChange}
      error={errors.includes('sponsorType')}
      options={[
        /*{ optionName: 'Video Game', optionValue: 'game' },
        { optionName: 'Product/event/other', optionValue: 'non-game' },*/
          { optionName: 'Game Promotion', optionValue: 'game' },
          { optionName: 'PR', optionValue: 'pr' },
          { optionName: 'Game Testing', optionValue: 'game-testing' },
          { optionName: 'Feedback', optionValue: 'feedback' },
          { optionName: 'Micro Streamers', optionValue: 'micro-streamers' },
          { optionName: 'Macro Streamers', optionValue: 'macro-streamers' },
      ]}
    />
    <CustomTextArea
      header="PRODUCT DESCRIPTION"
      name="description"
      value={form.description}
      onChange={handleFormChange}
      error={errors.includes('description')}
    />
      <CustomTextArea
          header="QUEST GOAL"
          name="questGoal"
          value={form.questGoal}
          onChange={handleFormChange}
          error={errors.includes('questGoal')}
      />
    <CustomTextField
      header="CREATOR / DEVELOPER"
      name="developer"
      value={form.developer}
      onChange={handleFormChange}
      error={errors.includes('developer')}
    />
    <CustomTextField
      header="PUBLISHER"
      name="publisher"
      value={form.publisher}
      onChange={handleFormChange}
      error={errors.includes('publisher')}
    />
    <CustomDate
      header="RELEASE DATE"
      name="releaseDate"
      value={form.releaseDate}
      onChange={handleFormChange}
      error={errors.includes('releaseDate')}
    />
      <CustomTextField
          header="WEBSITE URL"
          name="websiteUrl"
          value={form.websiteUrl}
          onChange={handleFormChange}
          error={errors.includes('websiteUrl')}
      />
      <CustomTextField
          header="HEADLINE"
          name="headline"
          value={form.headline}
          onChange={handleFormChange}
          error={errors.includes('headline')}
      />
      <CustomTextField
          header="HEADLINE VIDEO"
          name="headlineVideo"
          value={form.headlineVideo}
          onChange={handleFormChange}
          error={errors.includes('headlineVideo')}
      />
      <Categories
          value={form.categories}
          handleFormChange={handleFormChange}
      />
  </>
);

export default General;
